import React, { useState, useEffect } from 'react'
import { Tabs, Button, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import UnconfirmedTable from './events/UnconfirmedTable';
import CalendarsTable from './calendars/CalendarsTable';
import EventsTable from './events/EventsTable';
import WebCalTable from './webCal/WebCalTable';
import OOGOrdersTable from './oog/OOGOrdersTable';
import OOGNewsTable from './oog/OOGNewsTable';
import StatAgTable from './statAg/StatAgTable';
import EditAgTable from './statAg/EditAgTable';
import CommitteesTable from './committees/CommitteesTable';
import ContactInfoTable from './contactInfo/ContactInfoTable';
import UsersTable from './users/UsersTable';
import NotifyUsers from './notifyUsers/NotifyUsers'
import { signOut } from "firebase/auth";
import { db, auth } from '../../db/db';

function TabChooser() {
  const [eventPage, setEventPage] = useState('tracked')
  const [oogPage, setOogPage] = useState('orders')
  const [eventTable, setEventTable] = useState()
  const [oogTable, setOogTable] = useState()
  const [committeeData, setCommitteeData] = useState();

  useEffect(() => {
    if (!committeeData) {
      db.collection('committees').doc('1031').get()
        .then(doc => doc.data())
        .then(data => {
          setCommitteeData(data.committees)
        })
    }

  }, [committeeData])

  useEffect(() => {
    if (eventPage === 'tracked') {
      setEventTable(
        <div>
          <h2>Tracked Events</h2>
          <EventsTable committeeData={committeeData} />
        </div>)
    } else if (eventPage === 'unconfirmed') {
      setEventTable(
        <div>
          <h2>Unconfirmed Events</h2>
          <UnconfirmedTable committeeData={committeeData} />
        </div>)
    }
  }, [eventPage, committeeData])

  useEffect(() => {
    if (oogPage === 'orders') {
      setOogTable(
        <div>
          <h2>Orders</h2>
          <OOGOrdersTable />
        </div>)
    } else if (oogPage === 'news') {
      setOogTable(
        <div>
          <h2>News</h2>
          <OOGNewsTable />
        </div>)
    }
  }, [oogPage])

  const tabs = [
    {
      key: '1',
      label: `Events`,
      children:
        <div>
          <Button onClick={() => setEventPage('tracked')}>
            Tracked Events
            </Button>
          <Button onClick={() => setEventPage('unconfirmed')}>
            Unconfirmed Events
            </Button>
          {eventTable}
        </div>
    },
    {
      key: '2',
      label: `WebCal`,
      children: <WebCalTable committeeData={committeeData} />
    },
    {
      key: '3',
      label: `Calendars`,
      children: <CalendarsTable />
    },
    {
      key: '4',
      label: `State Agency Events`,
      children: <StatAgTable />,
    },
    {
      key: '5',
      label: `State Agencies`,
      children: <EditAgTable />,
    },
    {
      key: '6',
      label: `Committees`,
      children: <CommitteesTable />,
    },
    {
      key: '7',
      label: `Office of the Governor`,
      children:
        <div>
          <Button onClick={() => setOogPage('orders')}>
            Orders
        </Button>
          <Button onClick={() => setOogPage('news')}>
            News
        </Button>
          {oogTable}
        </div>
    },
    {
      key: '8',
      label: `Contact Info`,
      children: <ContactInfoTable />,
    },
    {
      key: '9',
      label: `Users`,
      children: <UsersTable />,
    },
    {
      key: '10',
      label: `Notify Users`,
      children: <NotifyUsers />,
    },
  ];

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('signed out')
      // Sign-out successful.
    }).catch((error) => {
      console.log("error")
      // An error happened.
    });
  }

  return (
    <div>
      <Card className='tabCard'>
        <div className='logoutButton'>
          <Button onClick={handleLogout} type="link" icon={<UserOutlined />}>Log Out</Button>
        </div>
        <Tabs className='tabs' defaultActiveKey="1" items={tabs} />
      </Card>
    </div>
  )
}

export default TabChooser
